import React, { useState } from 'react'
import IconButton from '../../../shared/buttons/icon-button'
import { UpgradeModal, ChangePlanButton, EditCard } from 'components'
import { useTranslation } from 'react-i18next'
import { useStorage } from 'contexts/storage-context'

export function IconSection({
  icon,
  orgId,
  setIcon,
  setImageIcon,
  imageIcon,
  setImageBase64
}: any) {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()
  const notAllowedIcons =
    activeOrganizationDetails?.plan?.controller?.name === 'adoptIcon' ||
    activeOrganizationDetails?.plan?.controller?.name === 'allIcons'

  function hasAccessIcon(name: 'adopt' | 'lock' | 'cookie' | 'custom' | 'add') {
    const accessMap = {
      adopt: ['adoptIcon', 'allIcons', 'logo'],
      lock: ['allIcons', 'logo'],
      cookie: ['allIcons', 'logo'],
      custom: ['logo'],
      add: ['logo']
    }

    const controllerName = activeOrganizationDetails?.plan?.controller?.name
    const allowedControllerNames = accessMap[name]

    return (
      controllerName !== undefined &&
      allowedControllerNames?.includes(controllerName)
    )
  }

  return (
    <div className="px-20">
      <EditCard className="mt-5">
        <div className="flex justify-between">
          <div>
            <div className="font-bold text-primary">{`${t(
              'disclaimer.style.Icon.Controller icon'
            )}`}</div>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <IconButton
            setImageBase64={setImageBase64}
            setImageIcon={setImageIcon}
            imageIcon={imageIcon}
            iconSelected={icon}
            setIcon={setIcon}
            hasAccess={hasAccessIcon('adopt')}
            icon="adopt"
            setOpenupgradeModal={setUpgradeModalOpen}
          />
          <IconButton
            setImageBase64={setImageBase64}
            setImageIcon={setImageIcon}
            imageIcon={imageIcon}
            iconSelected={icon}
            setIcon={setIcon}
            plan="Business"
            hasAccess={hasAccessIcon('lock')}
            icon="lock"
            setOpenupgradeModal={setUpgradeModalOpen}
          />
          <IconButton
            setImageBase64={setImageBase64}
            setImageIcon={setImageIcon}
            iconSelected={icon}
            imageIcon={imageIcon}
            setIcon={setIcon}
            plan="Business"
            hasAccess={hasAccessIcon('cookie')}
            icon="cookie"
            setOpenupgradeModal={setUpgradeModalOpen}
          />
          {imageIcon && (
            <IconButton
              setImageBase64={setImageBase64}
              setImageIcon={setImageIcon}
              imageIcon={imageIcon}
              iconSelected={icon}
              setIcon={setIcon}
              plan="Business"
              hasAccess={hasAccessIcon('custom')}
              icon="custom"
              setOpenupgradeModal={setUpgradeModalOpen}
            />
          )}
          <IconButton
            setImageBase64={setImageBase64}
            setImageIcon={setImageIcon}
            imageIcon={imageIcon}
            iconSelected={icon}
            setIcon={setIcon}
            plan="Business"
            hasAccess={hasAccessIcon('add')}
            icon="add"
            setOpenupgradeModal={setUpgradeModalOpen}
          />
          {notAllowedIcons && (
            <div className={'ml-8'}>
              <ChangePlanButton orgId={orgId}>
                {t('disclaimer.style.Upgrade')}
              </ChangePlanButton>
            </div>
          )}
        </div>
      </EditCard>
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </div>
  )
}
