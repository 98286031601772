import { gql } from '@apollo/client'

export const CREATE_DISCLAIMER = gql`
  mutation CreateDisclaimer(
    $name: String!
    $organizationId: String!
    $domains: [String]!
    $autoConfig: Boolean
  ) {
    create_disclaimer(
      object: {
        organizationId: $organizationId
        name: $name
        domains: $domains
        autoConfig: $autoConfig
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const CLONE_DISCLAIMER = gql`
  mutation CloneDisclaimer(
    $disclaimerId: String!
    $targetDisclaimerId: String
    $style: Boolean!
    $terms: Boolean!
    $policy: Boolean!
    $optout: Boolean!
    $options: Boolean!
  ) {
    clone_disclaimer(
      object: {
        disclaimerId: $disclaimerId
        targetDisclaimerId: $targetDisclaimerId
        style: $style
        terms: $terms
        policy: $policy
        optout: $optout
        options: $options
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const EDIT_DISCLAIMER = gql`
  mutation EditDisclaimer(
    $disclaimerId: String!
    $urls: [String]!
    $pathName: String!
  ) {
    edit_disclaimer(
      object: {
        disclaimerId: $disclaimerId
        domains: $urls
        pathName: $pathName
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
export const TRANSFER_DISCLAIMER = gql`
  mutation TransferDisclaimer(
    $disclaimerId: String!
    $newOrganizationId: String!
  ) {
    transfer_disclaimer(
      object: {
        disclaimerId: $disclaimerId
        newOrganizationId: $newOrganizationId
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const BLOCK_DISCLAIMER = gql`
  mutation BlockDisclaimer($disclaimerId: String!, $unblock: Boolean!) {
    block_disclaimer(
      object: { disclaimerId: $disclaimerId, unblock: $unblock }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
export const DELETE_DISCLAIMER = gql`
  mutation DeleteDisclaimer($id: String!) {
    delete_disclaimer(object: { disclaimerId: $id }) {
      data
      success
      message
      errorCode
      errorDetails
    }
  }
`
export const GET_DISCLAIMER_VERSION = gql`
  query ($disclaimerPathname: String!, $organizationId: uuid!) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
    ) {
      disclaimer_versions(limit: 1, order_by: { main_version: desc }) {
        terms_version
        disclaimer_version
        disclaimer_style_version
        policy_version
        opt_out_version
        disclaimer_options_version
        disclaimer_text_version
        disclaimer_website_version
        tags_version
        scan_auth_version
        vendors_version
      }
    }
  }
`

export const GET_DISCLAIMER = gql`
  query ($disclaimerPathname: String!, $organizationId: uuid!) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
    ) {
      id
      pathname
      created_at
      disclaimer_status {
        name
      }
      disclaimer_versions(limit: 1, order_by: { main_version: desc }) {
        disclaimer_version
        terms_version
        disclaimer_version
        disclaimer_style_version
        policy_version
        opt_out_version
        disclaimer_text_version
        disclaimer_website_version
        tags_version
      }
      disclaimer_websites {
        url
        version
      }
      tags(where: { name: { _eq: "AdOpt" } }, limit: 1) {
        name
      }
    }
  }
`

export const GET_DISCLAIMER_WITH_OPTIONS = gql`
  query (
    $pathname: String!
    $organizationId: uuid!
    $disclaimerOptionsVersion: Int
    $disclaimerWebsiteVersion: Int
    $disclaimerVendorsVersion: Int
  ) {
    disclaimers(
      where: {
        pathname: { _eq: $pathname }
        organization_id: { _eq: $organizationId }
      }
    ) {
      id
      pathname
      created_at
      disclaimer_status {
        name
      }
      vendors(where: { version: { _eq: $disclaimerVendorsVersion } }) {
        vendor_id
      }
      disclaimer_options(
        where: { version: { _eq: $disclaimerOptionsVersion } }
        limit: 1
        order_by: { version: desc }
      ) {
        uncheck_tags
        hide_disclaimer
        consent_ttl_in_days
        manual_block
        ignore_urls
        languages_configuration
        consent_wall
        tcf
      }
      disclaimer_websites(
        where: { version: { _eq: $disclaimerWebsiteVersion } }
      ) {
        url
        version
        disclaimer_installed
      }
      tags(where: { name: { _eq: "AdOpt" } }, limit: 1) {
        name
      }
    }
  }
`

export const GET_ORG_DISCLAIMER = gql`
  query ($organizationId: uuid!, $limit: Int) {
    disclaimers(
      limit: $limit
      where: { organization_id: { _eq: $organizationId } }
    ) {
      id
      pathname
    }
  }
`

export const SET_DISCLAIMER_OPTIONS = gql`
  mutation SetDisclaimerOptions(
    $disclaimerId: String!
    $consentTTLDays: Int
    $hideAfterConsent: Boolean
    $uncheckTags: Boolean
    $manualBlock: Boolean
    $enableTCF: Boolean
    $enableConsentWall: Boolean
    $ignoredUrls: [String]
    $fallBackLanguage: String
    $languagesToUse: [String]
    $injectorVersionNumber: String
  ) {
    set_disclaimer_options(
      object: {
        disclaimerId: $disclaimerId
        consentTTLDays: $consentTTLDays
        hideAfterConsent: $hideAfterConsent
        uncheckTags: $uncheckTags
        manualBlock: $manualBlock
        ignoredUrls: $ignoredUrls
        fallBackLanguage: $fallBackLanguage
        languagesToUse: $languagesToUse
        enableTCF: $enableTCF
        injectorVersionNumber: $injectorVersionNumber
        enableConsentWall: $enableConsentWall
      }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const CLEAN_DISCLAIMER_CACHE = gql`
  mutation CleanDisclaimerCache($disclaimerId: String!, $version: String) {
    clean_disclaimer_cache(
      object: { disclaimerId: $disclaimerId, version: $version }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`

export const GET_VENDORS = gql`
  mutation GetVendors {
    get_all_vendors_from_list {
      success
      errorCode
      errorDetails
      message
      data {
        id
        name
        urls {
          langId
          privacy
        }
      }
    }
  }
`

export const GET_INJECTOR_VERSIONS = gql`
  query GetInjectorVersions {
    injector_versions(
      where: { version_number: { _neq: "lastVersion" } }
      order_by: { created_at: desc }
    ) {
      version_number
      description
    }
  }
`
export const GET_DISCLAIMER_INJECTOR_VERSION = gql`
  query GetDisclaimerInjectorVersion(
    $disclaimerPathname: String!
    $organizationId: uuid!
  ) {
    disclaimers(
      where: {
        pathname: { _eq: $disclaimerPathname }
        organization_id: { _eq: $organizationId }
      }
    ) {
      id
      disclaimer_options(limit: 1, order_by: { version: desc }) {
        injector_version
      }
    }
  }
`
