import moment from 'moment'

export function formatMoney(number: string | number, fixed = 2) {
  if (number !== null && number !== undefined) {
    number = String(number).replace(',', '.')
    number = String(Number(number).toFixed(fixed))
    number = number.replace(/\D/g, '')
    number = number.replace(/(\d)(\d{2})$/, '$1,$2')
    number = number.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return number
  } else {
    return number
  }
}

export function formatUrl(str: string) {
  return String(str)
    .toLowerCase()
    .replace(new RegExp(/\s/g), '-')
    .replace(new RegExp(/[àáâãäå]/g), 'a')
    .replace(new RegExp(/æ/g), 'ae')
    .replace(new RegExp(/ç/g), 'c')
    .replace(new RegExp(/[èéêë]/g), 'e')
    .replace(new RegExp(/[ìíîï]/g), 'i')
    .replace(new RegExp(/ñ/g), 'n')
    .replace(new RegExp(/[òóôõö]/g), 'o')
    .replace(new RegExp(/œ/g), 'oe')
    .replace(new RegExp(/[ùúûü]/g), 'u')
    .replace(new RegExp(/[ýÿ]/g), 'y')
    .replace(new RegExp(/[/]/g), '-')
    .replace(/[^a-z0-9-]/g, '')
}

export function formatFileSize(bytes: number, decimals = 1) {
  if (bytes === 0) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function formatNumber(number: number) {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']
  const tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier == 0) return number ?? 0
  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale

  return scaled.toFixed(1) + suffix
}

export function formatMonthlyCron(date?: string) {
  const monthDay = date ? moment(date).date() : 1

  return `00 3 ${monthDay === 1 ? '1' : `${monthDay}`} * *`
}
