import React from 'react'
import { Button, ButtonType } from 'components'
import { ModalWrapper } from './modal-wrapper'

export default function ConfirmModal({
  modalOpen,
  setModalOpen,
  title,
  subtitle,
  buttonText,
  cancelText,
  icon,
  buttonAction
}: any) {
  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: icon,
        title: title,
        subtitle: subtitle
      }}
      modalSize="medium"
    >
      <div className="flex justify-end mt-8 gap-3">
        <Button
          buttonType={ButtonType.Secondary}
          onClick={() => setModalOpen(false)}
          className="font-bold px-5 py-2 dark:bg-black rounded"
        >
          {cancelText}
        </Button>
        <Button
          buttonType={ButtonType.Tertiary}
          onClick={() => buttonAction()}
          className="font-bold px-5 py-2 text-white bg-pink rounded"
        >
          {buttonText}
        </Button>
      </div>
    </ModalWrapper>
  )
}
