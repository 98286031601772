import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ColorPickerComp from '../../../shared/cards/color-picker-card'
import Image from 'next/image'
import IconStar from 'assets/imgs/icons/star.svg'
import { ChangePlanButton } from 'components/shared/buttons/change-plan-button'
import { UpgradeModal } from 'components/shared/modal/upgrade-modal'
import {
  InfoTooltip,
  TooltipPosition
} from 'components/shared/tooltip/infoTooltip'
import { paths } from 'config/paths'
import { EditCard } from 'components/shared/cards/edit-card'
import { useStorage } from 'contexts/storage-context'
import { Button, ButtonSize, ButtonType } from 'components/shared/buttons'
import { classNames } from 'util/shared'
import Radio from 'components/shared/radio/radio'

export function Colors(props: any) {
  const { t } = useTranslation()
  const [primaryOpen, setPrimaryOpen] = useState(false)
  const [textOpen, setTextOpen] = useState(false)
  const [bgOpen, setbgOpen] = useState(false)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const { activeOrganizationDetails } = useStorage()

  const primaryRef = useRef(null as any)
  const textRef = useRef(null as any)
  const bgRef = useRef(null as any)

  const {
    orgId,
    primaryColor,
    setPrimaryColor,
    textColor,
    setTextColor,
    bgColor,
    setBgColor,
    primaryColorDark,
    setPrimaryColorDark,
    textColorDark,
    setTextColorDark,
    bgColorDark,
    setBgColorDark,
    setOpenDisclaimer,
    activeTabColorTheme,
    setActiveTabColorTheme,
    useButtonsWithSameColor,
    setUseButtonsWithSameColor,
    ref
  } = props

  const tabsData = [
    {
      label: t('disclaimer.style.colors.lightTheme'),
      content: (
        <>
          <div className="flex justify-between">
            <div>
              <div className="font-bold text-primary">{`${t(
                'disclaimer.style.colors.Colors'
              )}`}</div>
              <div className="text-gray-400">{`${t(
                'disclaimer.style.colors.Select your disclaimer colors light'
              )}`}</div>
            </div>
          </div>
          <div className="flex mt-4">
            {/* Primary */}
            <div className="flex items-center">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Main'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setPrimaryOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setbgOpen(false)
                        setTextOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: primaryColor.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{primaryColor.hex}</div>
                </div>
                <div ref={primaryRef}>
                  <ColorPickerComp
                    color={primaryColor}
                    setColor={setPrimaryColor}
                    open={primaryOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none	'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
            {/* Text */}
            <div className="flex items-center ml-4">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Text'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setTextOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setbgOpen(false)
                        setPrimaryOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: textColor.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{textColor.hex}</div>
                </div>
                <div ref={textRef}>
                  <ColorPickerComp
                    color={textColor}
                    setColor={setTextColor}
                    open={textOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none	'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
            {/* Background */}
            <div className="flex items-center ml-4">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Background'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setbgOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setTextOpen(false)
                        setPrimaryOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: bgColor.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{bgColor.hex}</div>
                </div>
                <div ref={bgRef}>
                  <ColorPickerComp
                    color={bgColor}
                    setColor={setBgColor}
                    open={bgOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none	'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      label: t('disclaimer.style.colors.darkTheme'),
      content: (
        <>
          <div className="flex justify-between">
            <div>
              <div className="font-bold text-primary">{`${t(
                'disclaimer.style.colors.Colors'
              )}`}</div>
              <div className="text-gray-400">{`${t(
                'disclaimer.style.colors.Select your disclaimer colors dark'
              )}`}</div>
            </div>
          </div>
          <div className="flex mt-4">
            {/* Primary */}
            <div className="flex items-center">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Main'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setPrimaryOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setbgOpen(false)
                        setTextOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: primaryColorDark.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{primaryColorDark.hex}</div>
                </div>
                <div ref={primaryRef}>
                  <ColorPickerComp
                    color={primaryColorDark}
                    setColor={setPrimaryColorDark}
                    open={primaryOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
            {/* Text */}
            <div className="flex items-center ml-4">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Text'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setTextOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setbgOpen(false)
                        setPrimaryOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: textColorDark.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{textColorDark.hex}</div>
                </div>
                <div ref={textRef}>
                  <ColorPickerComp
                    color={textColorDark}
                    setColor={setTextColorDark}
                    open={textOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
            {/* Background */}
            <div className="flex items-center ml-4">
              <div className="mr-1">{`${t(
                'disclaimer.style.colors.Background'
              )}`}</div>
              <div className="relative">
                <div
                  onClick={() => {
                    setbgOpen(prev => {
                      if (!prev) {
                        setOpenDisclaimer(true)
                        setTextOpen(false)
                        setPrimaryOpen(false)
                      }
                      return !prev
                    })
                  }}
                  className="relative flex items-center p-1 border border-gray-200 rounded-sm cursor-pointer select-none dark:border-gray dark:bg-gray w-28"
                >
                  <div
                    style={{ background: bgColorDark.hex }}
                    className="w-4 h-4"
                  ></div>
                  <div className="ml-2">{bgColorDark.hex}</div>
                </div>
                <div ref={bgRef}>
                  <ColorPickerComp
                    color={bgColorDark}
                    setColor={setBgColorDark}
                    open={bgOpen}
                    className={
                      'absolute top-10 z-10 shadow-xl dark:shadow-none'
                    }
                  ></ColorPickerComp>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
  ]

  const buttonsWithSameColorSettings = [
    {
      name: t('disclaimer.style.override.no'),
      value: false,
      description: ''
    },
    {
      name: t('disclaimer.style.override.yes'),
      value: true,
      description: ''
    }
  ]

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (primaryRef?.current && !primaryRef.current.contains(event.target)) {
        setPrimaryOpen(false)
      }
      if (textRef?.current && !textRef.current.contains(event.target)) {
        setTextOpen(false)
      }
      if (bgRef?.current && !bgRef.current.contains(event.target)) {
        setbgOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  function handleActiveTabColorTheme(idx: number) {
    if (
      idx === 1 &&
      !activeOrganizationDetails?.plan?.can_edit_dark_light_css
    ) {
      setUpgradeModalOpen(true)
      return
    }

    setActiveTabColorTheme(idx)
  }

  return (
    <div className="px-20">
      <EditCard className="mt-5">
        <nav
          className="flex flex-1 -mb-px space-x-6 border-b border-gray-200 xl:space-x-8"
          aria-label="Tabs"
        >
          {tabsData.map((tab, idx) => {
            return (
              <Button
                buttonType={ButtonType.Tertiary}
                buttonSize={ButtonSize.Small}
                key={idx}
                style={{
                  borderBottom:
                    idx === activeTabColorTheme ? '' : '4px solid rgba(0,0,0,0)'
                }}
                className={classNames(
                  'py-2 border-b-4 transition-colors duration-300',
                  idx === activeTabColorTheme
                    ? 'border-primary-400 dark:text-primary text-primary'
                    : 'text-gray-400 hover:text-gray-700 dark:hover:text-primary hover:text-primary'
                )}
                onClick={() => handleActiveTabColorTheme(idx)}
              >
                <span className="relative">
                  {tab.label}
                  {idx === 1 &&
                    !activeOrganizationDetails?.plan
                      ?.can_edit_dark_light_css && (
                      <div className="absolute w-4 h-4 t -top-2 -right-4">
                        <Image src={IconStar} alt="Enterprise" />
                      </div>
                    )}
                </span>
              </Button>
            )
          })}
          <InfoTooltip
            position={TooltipPosition.LeftBottom}
            description={t('tooltip.helperText.darkLightTheme')}
            documentation={paths.documentation.darkLightTheme}
            className="flex py-2 mb-1"
          />
          {!activeOrganizationDetails?.plan?.can_edit_dark_light_css && (
            <div className="ml-12">
              <ChangePlanButton orgId={orgId}>
                {t('disclaimer.style.Upgrade')}
              </ChangePlanButton>
            </div>
          )}
        </nav>
        <div className="py-4">
          <p>{tabsData[activeTabColorTheme].content}</p>
          <div className="mt-4">
            <div className="flex justify-between">
              <div className="text-gray-400">{`${t(
                'disclaimer.style.colors.buttonsWithSameColor'
              )}`}</div>
            </div>
            <Radio
              state={useButtonsWithSameColor}
              setState={() =>
                setUseButtonsWithSameColor((prev: boolean) => !prev)
              }
              settings={buttonsWithSameColorSettings}
            />
          </div>
        </div>
      </EditCard>
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
    </div>
  )
}
