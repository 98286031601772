import React, { useState } from 'react'
import Image from 'next/image'
import logoDark from 'assets/imgs/dark-logo-horizontal.svg'
import logo from 'assets/imgs/negativo-logo-horizontal.svg'
import { Link } from 'components'
import { FiSettings, FiHelpCircle } from 'react-icons/fi'
import { IoExitOutline, IoList } from 'react-icons/io5'
import { BiTrophy, BiWorld } from 'react-icons/bi'
import { AiOutlineAudit } from 'react-icons/ai'
import { MdWebhook } from 'react-icons/md'

import {
  RiChatCheckLine,
  RiMenuFoldLine,
  RiMenuUnfoldLine
} from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import SideBarOption from './side-bar-option'
import { DiCode } from 'react-icons/di'
import { CgFileDocument } from 'react-icons/cg'
import { VscKey } from 'react-icons/vsc'
import SideBarOrganizationOption from './side-bar-organization-option'
import SideBarDisclaimerOption from './side-bar-disclaimer-option'
import SideBarPlanCard from 'components/shared/cards/side-bar-plan-card'
import { useTheme } from 'contexts/theme-context'
import { paths } from 'config/paths'
import { classNames } from 'util/shared'
import { BsFillBarChartFill } from 'react-icons/bs'
import { useStorage } from 'contexts/storage-context'
import { IoIosHeart } from 'react-icons/io'
import { useAuth } from 'contexts/auth-context'

export interface Link {
  name: string
  path: string
  subitem?: boolean
  target?: string
  icon: JSX.Element
}

export default function SideBar() {
  const { activeOrganizationDetails, activeDisclaimerPath } = useStorage()

  const [open, setOpen] = useState(true)
  const { theme } = useTheme()
  const { user } = useAuth()
  const { t } = useTranslation()

  function toogleSidebar() {
    setOpen(!open)
  }

  const upperDisclaimerLinks: Array<Link> = [
    {
      name: t('sideBar.Configurations'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}`
        : '#',
      icon: <FiSettings size={14} />
    },
    {
      name: t('sideBar.Style'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/style`
        : '#',
      subitem: true,
      icon: <RiChatCheckLine size={14} />
    },

    {
      name: t('sideBar.Documents'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/documents`
        : '#',
      subitem: true,
      icon: <CgFileDocument size={14} />
    },

    {
      name: t('sideBar.Opt-out'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/opt-out`
        : '#',
      subitem: true,
      icon: <IoExitOutline size={14} />
    },
    {
      name: t('sideBar.Installation'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/installation`
        : '#',
      subitem: true,
      icon: <VscKey size={14} />
    },
    {
      name: t('sideBar.Tags'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/tags`
        : '#',
      subitem: true,
      icon: <DiCode size={16} />
    }
  ]

  const bottomLinks: Array<Link> =
    user?.language === 'pt'
      ? [
          {
            name: t('sideBar.My organizations'),
            icon: <BiWorld size={16} />,
            path: paths.organizations
          },
          {
            name: t('sideBar.My plan'),
            icon: <BiTrophy size={16} />,
            path: paths.plan
          },
          {
            name: t('sideBar.accountConfiguration'),
            icon: <FiSettings size={16} />,
            path: '/configuration'
          },
          {
            name: t('sideBar.referral'),
            icon: <IoIosHeart size={16} />,
            path: '/referrals'
          },
          {
            name: t('sideBar.Help'),
            icon: <FiHelpCircle size={16} />,
            path: paths.support.documents,
            target: '_blank'
          }
        ]
      : [
          {
            name: t('sideBar.My organizations'),
            icon: <BiWorld size={16} />,
            path: paths.organizations
          },
          {
            name: t('sideBar.My plan'),
            icon: <BiTrophy size={16} />,
            path: paths.plan
          },
          {
            name: t('sideBar.accountConfiguration'),
            icon: <FiSettings size={16} />,
            path: '/configuration'
          },

          {
            name: t('sideBar.Help'),
            icon: <FiHelpCircle size={16} />,
            path: paths.support.documents,
            target: '_blank'
          }
        ]

  return (
    <div className="relative select-none bg-white dark:bg-black ">
      <div
        className={classNames(
          open ? 'w-64 ' : 'w-16',
          'duration-300 text-sm top-0 left-0 h-screen overflow-y-scroll overflow-x-hidden scroll-2 bg-shadow dark:bg-gray flex flex-col '
        )}
      >
        <div className="h-20 flex items-center justify-between pl-4 pt-1">
          {open ? (
            <>
              {theme === 'dark' ? (
                <Link
                  className="outline-none relative h-10 w-32"
                  href={paths.organizations}
                >
                  <Image
                    src={logoDark}
                    alt="Adopt logo"
                    layout="fill"
                    objectFit="contain"
                  />
                </Link>
              ) : (
                <Link
                  className="outline-none relative h-10 w-32"
                  href={paths.organizations}
                >
                  <Image
                    width="100"
                    src={logo}
                    alt="Adopt logo"
                    layout="fill"
                    objectFit="contain"
                  />
                </Link>
              )}
            </>
          ) : null}
          <button className="text-white h-12" onClick={toogleSidebar}>
            <div
              className={classNames(
                ' h-12 flex items-center  justify-center',
                open ? 'w-10' : 'w-14'
              )}
            >
              {open ? (
                <RiMenuFoldLine size={20} />
              ) : (
                <RiMenuUnfoldLine size={20} />
              )}
            </div>
          </button>
        </div>
        <div className={classNames(' flex flex-auto justify-between flex-col')}>
          <div>
            <>
              <SideBarOrganizationOption open={open} />
              <SideBarDisclaimerOption open={open} />
              {activeDisclaimerPath && (
                <>
                  {upperDisclaimerLinks?.map((link, index) => {
                    return (
                      <div key={index}>
                        <SideBarOption
                          index={index}
                          link={link}
                          open={open}
                        ></SideBarOption>
                      </div>
                    )
                  })}
                </>
              )}
              <div data-tour="dashboard-step">
                <SideBarOption
                  link={{
                    name: t('sideBar.Consent logs'),
                    path: activeOrganizationDetails?.pathname
                      ? `/org/${activeOrganizationDetails?.pathname}/consent-log`
                      : '#',
                    icon: <IoList size={14} />
                  }}
                  open={open}
                ></SideBarOption>
                <SideBarOption
                  link={{
                    name: t('sideBar.dashboard'),
                    path: activeOrganizationDetails?.pathname
                      ? `/org/${activeOrganizationDetails?.pathname}/dashboard`
                      : '#',
                    icon: <BsFillBarChartFill size={14} />
                  }}
                  open={open}
                ></SideBarOption>
                <SideBarOption
                  link={{
                    name: t('sideBar.audit'),
                    path: activeOrganizationDetails?.pathname
                      ? `/org/${activeOrganizationDetails?.pathname}/audit-log`
                      : '#',
                    icon: <AiOutlineAudit size={14} />
                  }}
                  open={open}
                ></SideBarOption>
                <SideBarOption
                  link={{
                    name: 'Webhooks',
                    path: activeOrganizationDetails?.pathname
                      ? `/org/${activeOrganizationDetails?.pathname}/webhooks`
                      : '#',
                    icon: <MdWebhook size={14} />
                  }}
                  open={open}
                ></SideBarOption>
              </div>
            </>
          </div>
          <div className="pb-1 mt-20">
            {open && <SideBarPlanCard />}
            <div className="border-t border-white-200 dark:border-gray-300">
              {bottomLinks?.map((link, index) => {
                return (
                  <div key={index}>
                    <SideBarOption
                      index={index}
                      key={index}
                      link={link}
                      open={open}
                    ></SideBarOption>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
