import React from 'react'
import { useTranslation } from 'react-i18next'

import Radio from 'components/shared/radio/radio'
import { EditCard } from 'components/shared/cards/edit-card'

interface OverrideClassesParams {
  hasImportant: boolean
  setHasImportant: React.Dispatch<React.SetStateAction<boolean>>
}

export function OverrideClasses({
  hasImportant,
  setHasImportant
}: OverrideClassesParams) {
  const { t } = useTranslation()
  const settings = [
    {
      name: t('disclaimer.style.override.no'),
      value: true,
      description: ''
    },
    {
      name: t('disclaimer.style.override.yes'),
      value: false,
      description: ''
    }
  ]

  function changeImportantState() {
    setHasImportant(oldState => !oldState)
  }

  return (
    <div className="px-20">
      <EditCard className="mt-5">
        <div className="flex justify-between">
          <div>
            <div className="text-primary font-bold">{`${t(
              'disclaimer.style.override.title'
            )}`}</div>
            <div className="text-gray-400">{`${t(
              'disclaimer.style.override.selectOption'
            )}`}</div>
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <Radio
            state={hasImportant}
            setState={changeImportantState}
            settings={settings}
          />
        </div>
        <div className="flex mt-4"></div>
      </EditCard>
    </div>
  )
}
