import { classNames } from 'util/shared'
import router, { useRouter } from 'next/router'
import OrganizationDropdrown from '../dropdown/organization-dropdown'
import { HiSelector } from 'react-icons/hi'
import { BiWorld } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { useStorage } from 'contexts/storage-context'

interface SideBarOrganizationOptionProps {
  open: boolean
}

export default function SideBarOrganizationOption({
  open
}: SideBarOrganizationOptionProps) {
  const { activeOrganizationDetails, organizationsData } = useStorage()

  const path = `/org/${activeOrganizationDetails?.pathname}/disclaimers`
  const orgPath = `/org/${activeOrganizationDetails?.pathname}`

  const { t } = useTranslation()
  const nextRouter = useRouter()
  function changePage(href: string) {
    if (activeOrganizationDetails?.pathname) {
      router.push(href)
    }
  }

  return (
    <div className={classNames('flex items-center w-full pr-2')}>
      <div
        onClick={() => changePage(path)}
        className={classNames(
          'flex w-full items-center text-white hover:text-primary cursor-pointer',
          nextRouter.asPath.includes(orgPath)
            ? 'opacity-100'
            : 'opacity-70 dark:opacity-50 '
        )}
      >
        <div
          className={classNames(
            open ? 'w-12' : 'w-14',
            'flex justify-center duration-100 h-10 items-center'
          )}
        >
          <BiWorld size={18} />
        </div>
        {open && (
          <div className="flex-1 flex justify-between items-center pr-2">
            <div className="font-bold truncate w-28  ">
              {activeOrganizationDetails?.name ?? t('sideBar.Organization')}
            </div>
          </div>
        )}
      </div>

      {open ? (
        <OrganizationDropdrown items={organizationsData}>
          <div className="w-6 h-8 ml-2 text-white hover:bg-shadow-200 dark:hover:bg-gray-400 cursor-pointer duration-200 rounded flex items-center justify-center ">
            <HiSelector size={20} />
          </div>
        </OrganizationDropdrown>
      ) : null}
    </div>
  )
}
