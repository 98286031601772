/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'components'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { paths } from 'config/paths'
import { useAuth } from 'contexts/auth-context'
import { useStorage } from 'contexts/storage-context'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function HeaderDropdrown({ user, children }: any) {
  const router = useRouter()
  const { t } = useTranslation()
  const { handleLogout } = useAuth()
  const { organizationsData } = useStorage()

  function logout() {
    handleLogout()
    router.push('/login')
  }

  return (
    <Menu as="div" className="relative inline-block text-left z-40">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex items-center text-gray-600 dark:text-white">
              {children}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 divide-opacity-25 dark:divide-opacity-100 focus:outline-none"
            >
              <div className="px-4 py-3">
                <p className="text-sm dark:text-white font-bold">
                  {t('headerDropdown.emailAccount')}
                </p>
                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                  {user?.email}
                </p>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <Link
                    href="/configuration"
                    className={
                      'dark:text-white-200 block px-4 py-2 text-sm hover:text-gray-200'
                    }
                  >
                    {t('headerDropdown.accountSetup')}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    href={paths.support.documents}
                    target={'_blank'}
                    className={
                      'dark:text-white-200 block px-4 py-2 text-sm hover:text-gray-200'
                    }
                  >
                    {t('headerDropdown.help')}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    href={paths.support.ticket}
                    target={'_blank'}
                    className={
                      'dark:text-white-200 block px-4 py-2 text-sm hover:text-gray-200'
                    }
                  >
                    {t('headerDropdown.openTicket')}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    href={`${paths.org.disclaimers.replace(
                      ':organizationPathname',
                      organizationsData?.[0]?.pathname!
                    )}?openTutorial=true`}
                    className={
                      'dark:text-white-200 block px-4 py-2 text-sm hover:text-gray-200'
                    }
                  >
                    {t('headerDropdown.tutorial')}
                  </Link>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={logout}
                    className={classNames(
                      'dark:text-white-200 block hover:text-gray-200 w-full text-left px-4 py-2 text-sm'
                    )}
                  >
                    {t('headerDropdown.logout')}
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
