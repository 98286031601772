import React from 'react'
import { classNames } from 'util/shared'
import router, { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Link } from './side-bar'
import { useHandleError } from 'hooks/useHandleError'

interface SideBarOptionProps {
  index?: number | string
  link: Link
  open: boolean
}

export default function SideBarOption({
  index,
  link,
  open
}: SideBarOptionProps) {
  const { t } = useTranslation()
  const nextRouter = useRouter()
  const { handleError } = useHandleError()

  function changePage(href: string) {
    if (!href.includes('undefined')) {
      if (link.target === '_blank') {
        window.open(href, '_ blank')
      } else {
        router.push(href)
      }
    } else {
      handleError(t('sideBar.Select a disclaimer'))
    }
  }
  return (
    <div key={index} className="cursor-pointer">
      <div
        onClick={() => changePage(link.path)}
        className={classNames(
          'text-white duration-300   hover:bg-light dark:hover:bg-black  flex items-center h-10 w-full  py-0 hover:text-white ',
          nextRouter.asPath === `${link.path}`
            ? 'opacity-100'
            : 'opacity-70 dark:opacity-50 ',
          link.subitem === true && open ? 'pl-5' : ''
        )}
      >
        <div className="flex items-center">
          <div
            className={classNames(
              open ? 'w-12' : 'w-14',
              'flex justify-center duration-100'
            )}
          >
            {link.icon}
          </div>
          {open ? <div className="font-bold truncate">{link.name}</div> : null}
        </div>
      </div>
    </div>
  )
}
