import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { AiOutlinePlus } from 'react-icons/ai'

import { Dropdown } from '../dropdown'
import { useDisclaimerConfig } from 'hooks/useDisclaimerConfig'
import { LanguagesTypes } from 'util/enums'

interface LanguagesOptions {
  name: string
  value: string
}

interface CountriesChipSelectProps {
  languageSelected: string
  setLanguageSelected: Dispatch<SetStateAction<string>>
  languageButtons: string[]
  setLanguageButtons: Dispatch<SetStateAction<string[]>>
}

const LanguageFlags = {
  it: '🇮🇹',
  pt: '🇧🇷',
  en: '🇺🇸',
  bg: '🇧🇬',
  hr: '🇭🇷',
  cs: '🇨🇿',
  da: '🇩🇰',
  nl: '🇳🇱',
  et: '🇪🇪',
  fi: '🇫🇮',
  fr: '🇫🇷',
  de: '🇩🇪',
  el: '🇬🇷',
  hu: '🇭🇺',
  ga: '🇮🇪',
  lv: '🇱🇻',
  lt: '🇱🇹',
  mt: '🇲🇹',
  pl: '🇵🇱',
  ro: '🇷🇴',
  sk: '🇸🇰',
  es: '🇪🇸',
  sv: '🇸🇪',
  hi: '🇮🇳',
  zh: '🇨🇳',
  ja: '🇯🇵',
  ar: '🇸🇦',
  'pt-PT': '🇵🇹',
  'en-GB': '🇬🇧',
  'es-LA': '🇲🇽',
  'zh-TW': '🇹🇼'
}

export function CountriesChipSelect({
  languageSelected,
  setLanguageSelected,
  languageButtons,
  setLanguageButtons
}: CountriesChipSelectProps) {
  const { t } = useTranslation()
  const [languagesList, setLanguagesList] = useState<LanguagesOptions[]>([])
  const [currentLanguageHover, setCurrentLanguageHover] = useState('')

  const showCountriesQuantity = 4

  const { data: disclaimerData, loading: disclaimerLoading } =
    useDisclaimerConfig()

  useEffect(() => {
    if (!disclaimerLoading) {
      const languagesData =
        disclaimerData?.disclaimer_options?.[0]?.languages_configuration
          ?.languages ?? Object.values(LanguagesTypes)

      const languagesFormatted = languagesData?.map((language: string) => ({
        value: language,
        name: t(`languages.${language}`)
      }))

      setLanguagesList(languagesFormatted)
    }
  }, [disclaimerData, disclaimerLoading])

  const dropdownLanguageOptions = useMemo(
    () =>
      languagesList
        .filter(({ value }) => !languageButtons?.includes(value))
        .map(({ name, value }) => ({
          label: name,
          onClick: () => setLanguageButtons(prev => [...prev, value])
        })),
    [languageButtons, languagesList]
  )

  return (
    <div className="flex gap-4">
      <div className="flex -space-x-2">
        {languageButtons?.slice(0, showCountriesQuantity).map(language => (
          <div
            onMouseEnter={() => setCurrentLanguageHover(language)}
            onMouseLeave={() => setCurrentLanguageHover('')}
            onClick={() => {
              setLanguageSelected(language)
            }}
            className={`h-10 flex items-center rounded-full whitespace-nowrap border border-gray-200 cursor-pointer bg-white-200 dark:bg-gray duration-300 ${
              languageSelected === language
                ? 'border-primary px-6 z-10'
                : 'hover:border-gray-400 dark:hover:border-white-200 p-3'
            }`}
          >
            <div
              className={`flex gap-2 items-center ${
                languageSelected === language ? 'border-primary' : ''
              }`}
            >
              {currentLanguageHover === language ||
              languageSelected === language ? (
                <span
                  className={`${
                    languageSelected === language
                      ? 'text-primary'
                      : 'text-gray dark:text-white-200'
                  }`}
                >
                  {t(`languages.${language}`)}
                </span>
              ) : (
                LanguageFlags[language as keyof typeof LanguageFlags]
              )}
            </div>
          </div>
        ))}
        {languageButtons?.length > showCountriesQuantity && (
          <div
            className={`text-gray-400 dark:text-white-200 h-10 p-2 flex items-center rounded-full border border-gray-200 bg-white-200 hover:border-gray-400 dark:bg-gray dark:hover:border-white-200 duration-300 `}
          >
            +{languageButtons?.length - showCountriesQuantity}
          </div>
        )}
        {languagesList?.length !== languageButtons?.length && (
          <Dropdown
            items={dropdownLanguageOptions}
            classes="max-h-52 overflow-y-scroll scroll-1 !-left-52"
          >
            <div
              className={`p-2 flex items-center   rounded-full border border-gray-200 cursor-pointer bg-white-200 hover:border-gray-400 dark:bg-gray dark:hover:border-white-200 duration-300 `}
            >
              <AiOutlinePlus
                className="text-gray-400 dark:text-white-200"
                size={22}
              />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  )
}
