import React from 'react'
import SideBar from './side-bar'
import { SpinLoading } from 'components'
import EmailVerifyBanner from 'components/shared/banner/email-verify-banner'
import PageWithoutPermission from 'components/shared/cards/page-without-permission'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'
import { classNames } from 'util/shared'
import InfoBanner from '../banner/info-banner'
// import { useAuth } from 'contexts/auth-context'

interface PageContentProps {
  pageScroll?: (e: any) => void
  loading?: boolean
  withoutPermission?: boolean
  className?: string
  children: any
}

export function PageContent({
  pageScroll,
  loading = false,
  withoutPermission = false,
  className,
  children
}: PageContentProps) {
  // const { user } = useAuth()

  // if (!user?.migratedDocumentsV2) return <MaintenancePage />

  return (
    <div className="flex w-screen">
      <SideBar />
      <div
        id="pageContent"
        onScroll={pageScroll}
        className={classNames(
          `scroll-1 h-screen overflow-y-scroll duration-300 bg-gray-50 dark:bg-black w-full pb-12 `,
          className
        )}
      >
        {loading ? (
          <div className="flex h-full justify-center items-center">
            <SpinLoading />
          </div>
        ) : (
          <>
            {withoutPermission ? (
              <PageWithoutPermission />
            ) : (
              <motion.div
                initial="initial"
                animate="animate"
                variants={fadeIn()}
              >
                <EmailVerifyBanner />
                <InfoBanner />
                {/* <ReferralBanner /> */}
                <div className='className="pb-16 pt-4"'>{children}</div>
              </motion.div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
