import { useMutation, useQuery } from '@apollo/client'
import {
  GET_DOCUMENTS,
  ADD_EDIT_COOKIE_DOCUMENT,
  DELETE_DOCUMENT,
  ADD_DOCUMENT_TO_DISCLAIMER,
  GET_DOCUMENT_BY_ID,
  ADD_EDIT_PRIVACY_DOCUMENT,
  ADD_EDIT_TERMS_DOCUMENT,
  GET_DOCUMENTS_BY_DISCLAIMER_ID,
  RESTORE_DOCUMENT,
  GET_VERSIONS_BY_DISCLAIMER_ID,
  ADD_SCAN_FOR_AI,
  LIST_DOCUMENTS
} from 'queries/documents'
import { useStorage } from 'contexts/storage-context'
import {
  AddEditDocumentProps,
  FilterByDocumentType,
  RawDocumentByDisclaimerIdProps,
  RawDocumentByIdProps,
  RawListDocumentProps,
  RawVersionListProps,
  UseDocumentsProps
} from 'models/documents'

function formatVersionList(
  data: RawVersionListProps | undefined,
  type: FilterByDocumentType
) {
  switch (type) {
    case 'cookies':
      return (
        data?.disclaimer_documents?.[0]?.document_master?.document_cookies ?? []
      )
    case 'privacy':
      return (
        data?.disclaimer_documents?.[0]?.documentMasterByDocumentMasterPrivacyId
          ?.document_privacies ?? []
      )
    case 'terms':
      return (
        data?.disclaimer_documents?.[0]?.documentMasterByDocumentMasterTermsId
          ?.document_terms ?? []
      )
    default:
      return []
  }
}

export function useDocuments(props: UseDocumentsProps) {
  const { documentId, disclaimerId, filterByDocumentType } = props

  const { activeOrganizationDetails } = useStorage()
  const [addEditCookieDocumentMutation] = useMutation(ADD_EDIT_COOKIE_DOCUMENT)
  const [addEditPrivacyDocumentMutation] = useMutation(
    ADD_EDIT_PRIVACY_DOCUMENT
  )
  const [addEditTermsDocumentMutation] = useMutation(ADD_EDIT_TERMS_DOCUMENT)
  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT)
  const [addDocumentToDisclaimerMutation] = useMutation(
    ADD_DOCUMENT_TO_DISCLAIMER
  )
  const [restoreDocumentMutation] = useMutation(RESTORE_DOCUMENT)
  const [addScanAiMutation] = useMutation(ADD_SCAN_FOR_AI)
  const [getDisclaimerQuantityMutation] = useMutation(LIST_DOCUMENTS)

  const {
    data: listDocuments,
    loading: listDocumentsLoading,
    refetch
  } = useQuery<RawListDocumentProps>(GET_DOCUMENTS, {
    fetchPolicy: 'network-only',
    skip: !activeOrganizationDetails?.id || !!documentId || !!disclaimerId,
    variables: { organizationId: activeOrganizationDetails?.id }
  })
  const { data: documentById, loading: documentByIdLoading } =
    useQuery<RawDocumentByIdProps>(GET_DOCUMENT_BY_ID, {
      fetchPolicy: 'network-only',
      skip: !documentId,
      variables: { documentId }
    })

  const {
    data: documentByDisclaimerId,
    loading: documentByDisclaimerIdLoading,
    refetch: refetchDocumentByDisclaimerId
  } = useQuery<RawDocumentByDisclaimerIdProps>(GET_DOCUMENTS_BY_DISCLAIMER_ID, {
    fetchPolicy: 'network-only',
    skip: !disclaimerId,
    variables: { disclaimerId }
  })

  const { data: versions, loading: versionsLoading } =
    useQuery<RawVersionListProps>(GET_VERSIONS_BY_DISCLAIMER_ID, {
      fetchPolicy: 'network-only',
      skip: !disclaimerId,
      variables: { disclaimerId }
    })

  async function addEditCookieDocument(
    language: string,
    options: AddEditDocumentProps
  ) {
    const result = await addEditCookieDocumentMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id,
        language,
        ...options
      }
    })
    return result?.data?.add_edit_cookie_document
  }

  async function addEditPrivacyDocument(
    language: string,
    options: AddEditDocumentProps
  ) {
    const result = await addEditPrivacyDocumentMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id,
        language,
        ...options
      }
    })
    return result?.data?.add_edit_privacy_document
  }

  async function addEditTermsDocument(
    language: string,
    options: AddEditDocumentProps
  ) {
    const result = await addEditTermsDocumentMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id,
        language,
        ...options
      }
    })
    return result?.data?.add_edit_terms_document
  }

  async function addDocumentToDisclaimer(
    masterDocumentId: string,
    disclaimerIds: string[] | string
  ) {
    const result = await addDocumentToDisclaimerMutation({
      refetchQueries: [
        {
          query: GET_DOCUMENTS_BY_DISCLAIMER_ID,
          variables: { disclaimerId: disclaimerIds[0] }
        }
      ],
      variables: {
        masterDocumentId,
        disclaimerIds
      }
    })
    return result?.data?.add_document_to_disclaimer
  }

  async function deleteDocument(docMasterId: string) {
    const result = await deleteDocumentMutation({
      variables: {
        docMasterId
      }
    })

    await refetch()
    return result?.data?.delete_document
  }

  async function restoreDocument(masterDocumentId: string, version: number) {
    const result = await restoreDocumentMutation({
      variables: {
        masterDocumentId,
        version
      }
    })
    return result?.data?.restore_document_version
  }

  async function getDisclaimerQuantity(documentMasterIds?: string[]) {
    if (activeOrganizationDetails?.id) {
      const result = await getDisclaimerQuantityMutation({
        variables: {
          organizationId: activeOrganizationDetails?.id,
          documentMasterIds: documentMasterIds ?? []
        }
      })
      return result?.data?.list_documents
    }
  }

  async function createScanAi(url: string, testBefore: boolean) {
    const result = await addScanAiMutation({
      variables: {
        url,
        testBefore
      }
    })
    return result?.data?.add_scan_for_AI
  }

  return {
    listDocuments,
    listDocumentsLoading,
    documentById: documentById?.document_master ?? [],
    documentByIdLoading,
    documentByDisclaimerId,
    documentByDisclaimerIdLoading,
    listVersions: formatVersionList(versions, filterByDocumentType) || [],
    listVersionsLoading: versionsLoading,
    refetchListDocuments: refetch,
    addEditCookieDocument,
    addEditPrivacyDocument,
    addEditTermsDocument,
    addDocumentToDisclaimer,
    deleteDocument,
    refetchDocumentByDisclaimerId,
    restoreDocument,
    createScanAi,
    getDisclaimerQuantity
  }
}
