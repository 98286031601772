import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguagesTypes, LegislationsTypes } from 'util/enums'
import IconStar from 'assets/imgs/icons/star.svg'
import {
  Button,
  ButtonType,
  EditCard,
  InfoTooltip,
  Select,
  TooltipPosition,
  UpgradeModal
} from 'components'
import { paths } from 'config/paths'
import { useStorage } from 'contexts/storage-context'
import EditableDisclaimerCard from 'components/shared/cards/editable-disclaimer-card'
import EditablePreferenceCard from 'components/shared/cards/editable-preference-card'
import EditablePreferenceCardAlternative from 'components/shared/cards/editable-preference-card-alternative'
import { BsGear } from 'react-icons/bs'
import LanguageConfigsModal from 'components/modal/language-configs-modal'
import { useDisclaimerConfig } from 'hooks/useDisclaimerConfig'
import { classNames } from 'util/shared'

export function Text({
  texts,
  setTexts,
  setTextsToEdit,
  language,
  setLanguages,
  legislation,
  setLegislation,
  cardStyle
}: any) {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [languageConfigsModalOpen, setLanguageConfigsModalOpen] =
    useState(false)
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()
  const [languagesList, setLanguagesList] = useState<string[]>([])

  const { data, loading } = useDisclaimerConfig()

  useEffect(() => {
    if (data && !loading) {
      const languagesData =
        data?.disclaimer_options?.[0]?.languages_configuration?.languages ??
        Object.values(LanguagesTypes)
      const languagesFallbackData =
        data?.disclaimer_options?.[0]?.languages_configuration?.fallback ?? 'pt'

      if (languagesData) {
        setLanguagesList(
          Object.values(LanguagesTypes).filter(language =>
            languagesData.includes(language)
          )
        )
      } else {
        setLanguagesList(Object.values(LanguagesTypes))
      }
      const currentLanguage =
        languagesData?.find(lang => lang === language) ?? languagesFallbackData

      setLanguages(currentLanguage)
    }
  }, [data, loading])

  function handleStateChange(value: string, kind: string, attribute: string) {
    value = value.replace(new RegExp(/["]/g), "'")

    setTextsToEdit((prev: any) => ({
      ...prev,
      [language]: { ...prev[language], [legislation]: true }
    }))

    setTexts((prev: any) => {
      return {
        ...prev,
        [language]: {
          ...prev[language],
          [legislation]: {
            ...prev[language][legislation],
            [kind]: {
              ...prev[language][legislation][kind],
              [attribute]: value
            }
          }
        }
      }
    })
  }
  function handleCategoriesStateChange(
    value: string,
    category: string,
    attribute: string
  ) {
    setTextsToEdit((prev: any) => ({
      ...prev,
      [language]: { ...prev[language], [legislation]: true }
    }))
    value = value.replace(new RegExp(/["]/g), "'")

    setTexts((prev: any) => {
      return {
        ...prev,
        [language]: {
          ...prev[language],
          [legislation]: {
            ...prev[language][legislation],
            categories: {
              ...prev[language][legislation].categories,
              [category]: {
                ...prev[language][legislation].categories[category],
                [attribute]: value
              }
            }
          }
        }
      }
    })
  }

  const legislationsMap: Record<string, Array<LegislationsTypes>> = {
    southAmerica: [LegislationsTypes.LGPD],
    northAmerica: [
      LegislationsTypes.PIPEDA,
      LegislationsTypes.LAW_25,
      LegislationsTypes.CCPA
    ],
    europe: [
      LegislationsTypes.GDPR,
      LegislationsTypes.CNIL,
      LegislationsTypes.NFADP,
      LegislationsTypes.UK_GDPR
    ],
    asia: [
      LegislationsTypes.DPDPA,
      LegislationsTypes.PDPL,
      LegislationsTypes.APPI,
      LegislationsTypes.PDPA_MALASIA,
      LegislationsTypes.PDPA_THAILANDIA,
      LegislationsTypes.PIPL
    ],

    africa: [LegislationsTypes.POPIA],
    oceania: [LegislationsTypes.APA]
  }

  function handleOpenLanguageConfigsModal() {
    setLanguageConfigsModalOpen(true)
  }

  return (
    <div className="px-20">
      <EditCard className="mt-5">
        <div className="flex gap-4 justify-between">
          <div className="flex-1">
            <div className="flex items-center">
              <div className="relative h-6 font-bold text-primary">
                <div className="relative flex items-center">
                  {t('disclaimer.style.Text.title')}

                  {!activeOrganizationDetails?.plan
                    ?.can_edit_disclaimer_text && (
                    <div className="absolute w-4 h-4 t -top-2 -right-4">
                      <Image src={IconStar} alt="Business" />
                    </div>
                  )}
                </div>
              </div>
              <InfoTooltip
                position={TooltipPosition.LeftBottom}
                className={'ml-4'}
                description={t('tooltip.helperText.style', {
                  languages: Object.keys(LanguagesTypes).length
                })}
                documentation={t('links.documentation.style')}
                video={paths.tutorials.style}
              />
            </div>

            <div className="text-gray-400  whitespace-pre-line	">
              {`${t('disclaimer.style.Text.description', {
                languages: Object.keys(LanguagesTypes).length
              })}`}
            </div>
          </div>
          <Select
            containerClasses={'!w-[220px]'}
            label={t('disclaimer.style.Text.legislation')}
            hasSections
            params={Object.entries(legislationsMap).reduce(
              (
                acc: { name: string; value: undefined | string }[],
                [region, legislations]
              ) => {
                acc.push({
                  name: t(`disclaimer.style.Text.regions.${region}`),
                  value: undefined
                })

                legislations.forEach(legislation => {
                  acc.push({
                    name: t(
                      `disclaimer.style.Text.legislations.${legislation}`
                    ),
                    value: legislation
                  })
                })

                return acc
              },
              []
            )}
            value={legislation}
            onChange={e => {
              setLegislation(e.value)
            }}
          />
          <div className="space-y-4 w-[220px]">
            <Select
              containerClasses={'w-full'}
              label={t('disclaimer.style.Text.language')}
              params={languagesList?.map(language => {
                return {
                  name: t(`languages.${language}`),
                  value: language
                }
              })}
              value={language}
              onChange={e => {
                setLanguages(e.value)
              }}
            />
            <Button
              buttonType={ButtonType.Secondary}
              iconLeft={BsGear}
              onClick={() => handleOpenLanguageConfigsModal()}
              className="!whitespace-nowrap h-11 w-full"
            >
              {t('disclaimer.style.Text.languageConfigButton')}
            </Button>
          </div>
        </div>

        <div
          onClick={() => {
            if (
              !activeOrganizationDetails?.plan?.can_edit_disclaimer_text &&
              !upgradeModalOpen
            ) {
              setUpgradeModalOpen(true)
            }
          }}
          className={classNames(
            'flex mt-10 flex-wrap gap-5 items-end justify-between ',
            !activeOrganizationDetails?.plan?.can_edit_disclaimer_text &&
              'opacity-40'
          )}
        >
          <div className="flex-1">
            <EditableDisclaimerCard
              readOnly={
                !activeOrganizationDetails?.plan?.can_edit_disclaimer_text
              }
              texts={texts}
              language={language}
              legislation={legislation}
              handleStateChange={handleStateChange}
            />
          </div>
          <div className="w-[420px] flex justify-end">
            {cardStyle === 'alternative' ? (
              <EditablePreferenceCardAlternative
                readOnly={
                  !activeOrganizationDetails?.plan?.can_edit_disclaimer_text
                }
                texts={texts}
                language={language}
                legislation={legislation}
                handleStateChange={handleStateChange}
                handleCategoriesStateChange={handleCategoriesStateChange}
              />
            ) : (
              <EditablePreferenceCard
                readOnly={
                  !activeOrganizationDetails?.plan?.can_edit_disclaimer_text
                }
                texts={texts}
                language={language}
                legislation={legislation}
                handleStateChange={handleStateChange}
                handleCategoriesStateChange={handleCategoriesStateChange}
              />
            )}
          </div>
        </div>
      </EditCard>
      <UpgradeModal
        modalOpen={upgradeModalOpen}
        setModalOpen={setUpgradeModalOpen}
      />
      <LanguageConfigsModal
        modalOpen={languageConfigsModalOpen}
        setModalOpen={setLanguageConfigsModalOpen}
        setLanguagesList={setLanguagesList}
        setLanguageDefault={setLanguages}
      />
    </div>
  )
}
