import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from 'public/animation/motion'

interface AccordionProps {
  title: string
  icon: ReactNode
  children: ReactNode
  isOpen: boolean
  onClick: () => void
}

export function Accordion({
  title,
  icon,
  children,
  isOpen,
  onClick
}: AccordionProps) {
  return (
    <div>
      <div
        className={`${
          isOpen ? 'text-primary' : 'hover:text-gray-400 dark:hover:text-white'
        } flex gap-3 text-gray-200  duration-500 items-center cursor-pointer`}
        onClick={onClick}
      >
        {icon}
        <div>{title}</div>
      </div>
      {isOpen && (
        <motion.div
          initial="initial"
          animate="animate"
          variants={fadeIn()}
          className="pl-8 pt-2"
        >
          <div className="text-gray-700 bg-gray-50">{children}</div>
        </motion.div>
      )}
    </div>
  )
}
