import React from 'react'
import { classNames } from 'util/shared'
import { useTranslation } from 'react-i18next'
import { RiArrowRightSFill } from 'react-icons/ri'
import DisclaimerDropdrown from 'components/shared/dropdown/disclaimer-dropdown'
import { useStorage } from 'contexts/storage-context'

interface SideBarDisclaimerOptionProps {
  open: boolean
}

export default function SideBarDisclaimerOption({
  open
}: SideBarDisclaimerOptionProps) {
  const { activeOrganizationDetails, activeDisclaimerPath } = useStorage()

  const { t } = useTranslation()

  return (
    <div className={classNames('flex items-center w-full pr-2')}>
      {open ? (
        <DisclaimerDropdrown
          items={activeOrganizationDetails?.disclaimers}
          activeOrganization={activeOrganizationDetails}
        >
          <div
            className=" h-10 text-primary ml-2  w-52
           cursor-pointer  rounded
          border border-primary pr-2
          flex items-center"
          >
            <div
              className={classNames(
                'w-8 flex justify-center duration-100  items-center'
              )}
            >
              <RiArrowRightSFill />
            </div>
            <div className="font-bold truncate w-44 text-left ">
              {activeDisclaimerPath &&
              activeOrganizationDetails?.disclaimers?.some(
                item => item.pathname === activeDisclaimerPath
              )
                ? activeDisclaimerPath
                : t('sideBar.Select a disclaimer')}
            </div>
          </div>
        </DisclaimerDropdrown>
      ) : null}
    </div>
  )
}
