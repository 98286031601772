import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'components'
import { useTranslation } from 'react-i18next'
import { OrganizationsData } from 'hooks/storage/useOrganizations'

interface OrganizationDropdrownProps {
  children: React.ReactNode
  items: OrganizationsData[] | undefined
}

export default function OrganizationDropdrown({
  children,
  items
}: OrganizationDropdrownProps) {
  const { t } = useTranslation()
  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      {({ open }) => (
        <>
          <Menu.Button className="relative flex items-center text-gray-600 dark:text-white">
            {children}
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              style={{ zIndex: 100 }}
              className=" origin-top-right absolute cursor-pointer right-1 top-8 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-black ring-1 ring-black ring-opacity-5 divide-y divide-gray focus:outline-none pointer"
            >
              <div className="px-4 pt-2 pb-4 font-bold dark:text-white">
                {t('sideBar.My')}{' '}
                <span className="text-primary">
                  {t('sideBar.organizations')}
                </span>
              </div>
              {items?.length !== 0 ? (
                <>
                  {items?.map((item, index) => {
                    return (
                      <Menu.Button
                        className="block w-full text-left"
                        key={index}
                      >
                        <Link href={`/org/${item?.pathname}/disclaimers`}>
                          <div
                            className={
                              'pointer  dark:text-white-200 block px-4 py-2 text-sm hover:text-gray-200'
                            }
                          >
                            {item?.name}
                          </div>
                        </Link>
                      </Menu.Button>
                    )
                  })}
                </>
              ) : (
                <div>
                  <div className="text-sm dark:text-white py-8 text-center w-full px-4">
                    {t("sideBar.You don't have any organization yet.")}
                  </div>
                </div>
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
