import React from 'react'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { BiDollar, BiWorld } from 'react-icons/bi'
import { FiUsers } from 'react-icons/fi'
import { HiOutlineThumbUp } from 'react-icons/hi'
import { fadeIn } from 'public/animation/motion'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'util/format'
import moment from 'moment'
import { Tooltip } from '../tooltip'
import { useStorage } from 'contexts/storage-context'

export default function OrganizationHeader() {
  const { t } = useTranslation()

  const { activeOrganizationDetails } = useStorage()
  return (
    <div className="px-10 dark:text-white py-5">
      <div className="flex items-center">
        {activeOrganizationDetails?.logo ? (
          <motion.div
            initial="initial"
            animate="animate"
            variants={fadeIn()}
            className="fade-in bg-white-200 dark:bg-gray w-36 h-36 rounded flex justify-center items-center text-primary-400"
          >
            <img
              className="rounded"
              src={activeOrganizationDetails?.logo}
              alt="Logo"
            />
          </motion.div>
        ) : (
          <div className="bg-white-200 dark:bg-gray w-36 h-36 rounded flex justify-center items-center text-primary-400">
            <BiWorld size={50} />
          </div>
        )}
        <div className="ml-7">
          <div className="flex items-center text-lg">
            <span>{t('org.header.Organization')}:</span>
            <div className="text-primary-400 ml-2">
              <BsFillCaretRightFill size={14} />
            </div>
            <div>{activeOrganizationDetails?.name}</div>
          </div>
          <div className="text-gray-600 ">
            {t(
              'org.header.Complete all steps below to get your disclaimer working correctly'
            )}
            .
          </div>
          <div className="flex items-center mt-2">
            <div className="rounded-full w-8 h-8 text-primary flex items-center justify-center mr-3 bg-white-200 dark:bg-gray">
              <BiDollar size={16} />
            </div>
            <div className="rounded-full h-5 font-bold">
              {t('org.header.Plan')}:
              <span className="text-primary capitalize ml-1">
                {activeOrganizationDetails?.plan.name}
              </span>
            </div>
            <div className="rounded-full ml-8 w-8 h-8 text-primary flex items-center justify-center mr-3 bg-white-200 dark:bg-gray">
              <HiOutlineThumbUp size={16} />
            </div>
            <Tooltip
              content={`${t('org.header.tooltipConsents')} ${moment().format(
                'MMM'
              )} (${moment().format('[01/]MM')} - ${moment().format(
                'DD[/]MM'
              )})`}
            >
              <div className="rounded-full h-5 font-bold">
                {t('org.header.Consents')}:
                <span className="text-primary ml-2">
                  {formatNumber(
                    activeOrganizationDetails?.metrics?.opt_ins ?? 0
                  )}
                </span>
              </div>
            </Tooltip>
            <div className="rounded-full ml-8 w-8 h-8 text-primary flex items-center justify-center mr-3 bg-white-200 dark:bg-gray">
              <FiUsers size={16} />
            </div>
            <Tooltip
              content={`${t('org.header.tooltipUniqueUsers')} ${moment().format(
                'MMM'
              )} (${moment().format('[01/]MM')} - ${moment().format(
                'DD[/]MM'
              )})`}
            >
              <div className="rounded-full h-5 font-bold">
                {t('org.header.Unique visitors')}:
                <span className="text-primary ml-2">
                  {formatNumber(
                    activeOrganizationDetails?.metrics?.unique_users ?? 0
                  )}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
