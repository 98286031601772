import { IoClose } from 'react-icons/io5'
import { classNames } from 'util/shared'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'hooks/useLocalstorage'
import { Button, ButtonType } from '../buttons'
import { RiHandHeartFill } from 'react-icons/ri'

export default function InfoBanner() {
  const { t } = useTranslation()
  const [open, setOpen] = useLocalStorage<string>('adoptHelpRs', 'true')

  return (
    <div
      className={classNames(
        'bg-lightblue duration-200 overflow-hidden flex items-center justify-between pr-10 text-white gap-4 relative',
        open == 'true' ? 'h-auto py-4' : 'h-0'
      )}
    >
      <div className="flex items-center ">
        <div className="bg-black bg-opacity-20 rounded-r-full pl-4 pr-4 mr-2 py-2 flex items-center gap-2">
          <RiHandHeartFill size={25}></RiHandHeartFill>

          {t('infoBanner.title')}
        </div>
        <span className="text-sm">
          {t('infoBanner.description')}

          <a
            target="_blank"
            className="underline ml-1 inline"
            href={t('infoBanner.link')} rel="noreferrer"
          >
            {t('infoBanner.checkDetails')}
          </a>
          {t('infoBanner.description2')}
        </span>
      </div>
      <Button buttonType={ButtonType.Ghost} onClick={() => setOpen('false')}>
        <IoClose className="text-white" size={16} />
      </Button>
    </div>
  )
}
